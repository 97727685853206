footer {
  background-color: $background-color;
  padding-top: 2 * $spacing-unit;
  margin-bottom: $spacing-unit * 4;

  .footer-content-wrapper {}

  .footer-grid {
    @extend .content;

    display: grid;
    grid-template-columns: 100%;
    width: calc(100% - $spacing-unit);
    margin-left: $spacing-unit;
    margin-right: $spacing-unit;
    color: $dark-grey-color;

    @media screen and (min-width: $size-xs) {
      grid-template-columns: 50% 50%;
    }

    .footer-col {}

    .site-name {
      display: inline-block;

      img {
        width: 182px;
        height: 39px;
      }

      &:hover {
        img.logo-gray {
          display: none;
        }

        img.logo-gray-red {
          display: block;
        }
      }

      img.logo-gray-red {
        display: none;
      }
    }

    .social {
      margin: 0;
      margin-top: $spacing-unit;
      text-align: left;

      @media screen and (min-width: $size-xs) {
        text-align: right;
        margin-top: 0;
      }

      .social-media-list {
        margin-left: 0;

        li {
          display: inline-block;
          margin-right: 0.5 * $spacing-unit;
          list-style: none;
          line-height: 1.5 * $base-line-height;

          &:last-child {
            margin-right: 0;
          }

          a {
            .svg-icon {
              width: 16px;
              height: 16px;
              padding-right: 0.25 * $spacing-unit;
              position: relative;
              top: 3px;
              fill: $dark-grey-color;
            }

            &:hover {
              .svg-icon {
                fill: $accent-color;
              }
            }
          }
        }
      }
    }

    a {
      color: $dark-grey-color;

      &:hover {
        text-decoration: none;
        color: $accent-color;
      }
    }
  }
}
