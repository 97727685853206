@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Regular'), local('Roboto-Regular'), url(fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Cascadia Code';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Cascadia Code'), local('Cascadia'), url(fonts/Cascadia.ttf) format('truetype');
}