.post {
  .post-heading, .post-date, .post-content {
    @extend .content;
  }

  .post-container {
    @extend .content-wrapper;

    background-color: $inner-body-background-color;
    overflow: auto; /** See: https://stackoverflow.com/a/9519933/1778121 **/
    position: relative;
    top: -25px;
    margin-left: 0.5 * $spacing-unit;
    margin-right: 0.5 * $spacing-unit;
    width: calc(100% - #{$spacing-unit});

    transition: top 1s;

    @media screen and (max-width: $size-xs) {
      margin-left: 0.25 * $spacing-unit;
      margin-right: 0.25 * $spacing-unit;
      width: calc(100% - #{0.5 * $spacing-unit});
    }

    @media screen and (min-width: $size-post-collapse) {
      top: -100px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .post-heading-background {
      display: block;
      height: 25px;
      position: absolute;
      width: calc(100% - 2px);
      border-top: 1px $grey-color solid;
      border-left: 1px $grey-color solid;
      border-right: 1px $grey-color solid;
      pointer-events: none;

      transition: height 1s;

      @media screen and (min-width: $size-post-collapse) {
        height: 100px;
      }
    }
  }

  .post-image-container {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    position: relative;

    .post-image-credit-button {
      position: absolute;
      right: 0.25 * $spacing-unit;
      bottom: 25px;
      width: 14px;
      opacity: 0.45;
      height: 14px;
      line-height: 14px;
      color: $text-color;
      background-color: #fff;
      font-size: 14px;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;

      transition: bottom 1s, right 1s;

      &:hover {
        opacity: 1;
        text-decoration: none;
        &~ .post-image-credit {
          display: inline-block;
        }
      }

      @media screen and (min-width: $size-post-collapse){
        bottom: 0px;
        right: 0;
      }
    }

    .post-image-credit {
      display: none;
      color: $text-color;
      background-color: #fff;
      font-size: 12px;
      position: absolute;
      line-height: 14px;
      right: 0.25 * $spacing-unit + 14px;
      bottom: 25px;
      vertical-align: middle;
      height: 14px;
      padding-right: 4px;
      padding-left: 4px;

      @media screen and (min-width: $size-post-collapse){
        bottom: 0px;
        right: 14px;
      }
    }

    &.active {
      &~ .post-container{
        top: 0;

        .post-heading-background{
          height: 0;
        }
      }
      .post-image-credit-button {
        bottom: 0;
        right: 0;
      }
    }

  }

  .post-heading {
    margin-top: 0.5 * $spacing-unit;
    margin-bottom: 0;

    .post-link {
      color: $text-color;
    }
  }

  .post-date {
    margin-bottom: 0.75 * $spacing-unit;
  }

  .post-content {
    
  }

  .post-end {
    float: right;
    width: 10px;
    height: 10px;
    background-color: $accent-color;
    margin-top: $spacing-unit;
    margin-right: $spacing-unit;
    cursor: pointer;
    transition: background-color 5s;

    &:hover {
      background-color: $inner-body-background-color;
    }
  }
}
