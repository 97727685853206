/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $accent-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  border-top: 1px $accent-color solid;
}

.outer-body {
  background-color: $background-color;
}

.inner-body {
  background-color: $inner-body-background-color;
  margin: 0 auto;
  width: 100%;
  max-width: $inner-body-width; 
}

.content {
  margin-left: $spacing-unit;
  margin-right: $spacing-unit;
}

.content-wrapper {
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;
  margin-bottom: 2 * $spacing-unit;
  clear: both;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

h1, h2, h3, h4, h5 {
  margin-top: 1.5 * $spacing-unit;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
  margin-top: 0.5 * $spacing-unit;
  margin-bottom: 0.25 * $spacing-unit;
}
img + em, .screenshots-container + em {
  font-size: 0.8em;
  text-align: right;
  display: block;
  color: $light-text-color;
  margin-bottom: 0.5 * $spacing-unit;
}

.screenshots-container {
  height: 400px;
  max-width: 100%;
  overflow-x: scroll;
  padding-top: 5px;
  padding-bottom: 5px;

  .screenshots{
    display: flex;
    align-items: baseline;
    height: 100%;
  
    & > img {
      margin-right: 10px;
      max-width: unset;
      border: 1px solid $medium-grey-color;
      border-radius: 5px;
      height: calc(100% - (0.25 * #{$spacing-unit}) - (0.5 * #{$spacing-unit}));
    }

  }
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $heading-font-weight;
}



/**
 * Links
 */
a {
  color: $accent-color;
  text-decoration: none;

  &:visited {
    
  }

  &:hover {
    color: lighten($accent-color, 10%);
  }

  &.file-pdf {
    background-image: url(icons/pdf.gif);
    background-position: left top;
    background-repeat: no-repeat;
    padding-left: 24px;
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color;
  border-radius: 3px;
  background-color: #eef;
  font-family: 'Cascadia Code', 'Consolas', 'Courier New', Courier;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}


/**
 * Recurring elements
 */
.accent-dot {
  color: $accent-color;
}