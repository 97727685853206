.site-header {
  // Positioning context for the mobile navigation icon
  position: relative;
  margin-bottom: 2 * $spacing-unit;

  .site-title {
    margin-left: $spacing-unit;

    .site-logo {
      height: $header-height;
      margin-top: -2.5px;
    }
  }

  /** Nav on mobile devices **/
  @media screen and (max-width: $size-xs - 1) {
    .site-nav {
      .nav-trigger {
        display: none;
      }

      .nav-button {
        position: absolute;
        top: $spacing-unit;
        right: $spacing-unit;
        transition: transform 1s;
        cursor: pointer;

        .nav-icon {
          fill: $text-color;
        }
      }

      .nav-items {
        display: none;
        background-color: $accent-color;

        .nav-item {
          display: block;
          padding-left: $spacing-unit;
          padding-left: $spacing-unit;
          padding-top: 0.25 * $spacing-unit;
          padding-bottom: 0.25 * $spacing-unit;
          color: $accent-color-inverse;
          font-size: $large-font-size;
          text-transform: uppercase;

          &:hover {
            text-decoration: none;
            background-color: darken($accent-color, 5%);
          }
        }
      }

      .nav-trigger:checked ~ .nav-items {
        display: block;
        max-height: 250px;
      }

      .nav-trigger:checked ~ .nav-button {
        transform: rotate(90deg);

        .nav-icon {
          fill: $accent-color;
        }
      }
    }
  }

  /** Nav on devices larger than mobile **/
  @media screen and (min-width: $size-xs) {
    .site-nav {
      .nav-trigger {
        display: none;
      }

      .nav-button {
        display: none;
      }

      .nav-items {
        float: right;
        position: absolute;
        top: $spacing-unit;
        right: $spacing-unit;

        .nav-item {
          font-size: $large-font-size;
          margin-right: $spacing-unit;
          text-transform: uppercase;
          color: $text-color;
          text-decoration: none;

          &.current {
            border-bottom: 3px solid $accent-color;
          }

          &:hover {
            color: $accent-color;
          }

          &:last-child {
            margin-right: 0;
          }
          
        }
      }
    }
  }
}
