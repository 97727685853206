@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$heading-font-weight: 300 !default;
$small-font-size: $base-font-size * 0.875 !default;
$large-font-size: $base-font-size * 1.125 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 25px !default;

$text-color: #2C343B !default;
$light-text-color: lighten(#2C343B, 50%) !default;
$background-color: #F0F0F0 !default;
$inner-body-background-color: #ffffff !default;
$accent-color: #C44741 !default;
$accent-color-inverse: #fff !default;

$grey-color: #F0F0F0 !default;
$medium-grey-color: darken($grey-color, 12%) !default;
$dark-grey-color: darken($grey-color, 25%) !default;

// Height of the header
$header-height: 52.5px;

// Width of the content area
$inner-body-width: 1200px !default;
$content-width: 768px !default;

$size-xs: 576px !default;
$size-sm: 768px !default;
$size-md: 992px !default;
$size-lg: 1200px !default;
$size-post-collapse: $content-width + 2 * $spacing-unit;

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.

@import "minima/fonts";
@import "minima/base";
@import "minima/header";
@import "minima/syntax-highlighting";
@import "minima/page";
@import "minima/about";
@import "minima/post";
@import "minima/footer";