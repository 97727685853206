.page {
  min-height: 1024px;
  .page-heading, .page-content {
    @extend .content;
  }

  .page-content-container {
    @extend .content-wrapper;

    .page-heading {

    }

    .page-content {

    }

  }

}
