.about {
  display: grid;
  grid-template-columns: 100%;

  @media screen and (min-width: $size-xs) {
    grid-template-columns: 60% 40%;
  }

  .text {
    
  }

  .picture {
    text-align: center;
    img {
      width: 100%;
      max-width: 200px;
      border: 1px solid $medium-grey-color;
      border-radius: 50%;
    }
  }
}